@import url('https://fonts.googleapis.com/css?family=Roboto:400,500&display=swap&subset=cyrillic');

:root {
    --color-grey-light: #546276;
    --color-grey-dark: #36495C;
    --color-text-field-blue: #eaf1fa;
    --color-button-hover: rgb(56, 73, 97);
    --color-li-hover: #F2F3F4;
    --color-red-logo: #e41e13;
    --color-grey-lighter: #7F8998;
  }

body {
    background-image: linear-gradient(0deg, #DEE4EC 0%, rgba(203, 211, 211, 0) 100%);
    background-attachment: fixed;
    margin: 0px;
    height: 100%;
    font-family: 'Roboto', sans-serif;
    color: var(--color-grey-dark);
    font-size: 16px;
  }
  
/*
.app, #root, html{
    height: 100%;
}
*/



a{
    text-decoration: none;
    color: var(--color-grey-dark)
}
h1, h2, h3{
    color: var(--color-grey-dark);
    font-weight: 500;  
    margin: 5px;  
}
h1 {
    font-size: 26px;
}
h2 {
    font-size: 20px;
}
h3 {
    font-size: 18px;
}

.header {
    padding: 0px 30px;
    background-color: white;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
    height: 100px;
    display: flex;
    flex-direction: row;   
    justify-content: space-between; 
}

.logo{
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
}
.github-link{
    display: flex;
    margin: auto 3% auto 0;
}
.github-link a{
    display: flex;
    flex-direction: row;
}
.github-link__text{    
    color: #dcdcdc;
    font-size: 18px;
    transition: color 0.3s ease-in;
}
.github-link svg{
    margin-right: 5px;
    width: 18px;
    height: 18px;
    fill: #dcdcdc;    
    transition: fill 0.3s ease-in;
}
.github-link:hover .github-link__text{
    color: var(--color-grey-light);
    transition: color 0.3s ease-in;
}
.github-link:hover svg {    
    fill: var(--color-grey-light);
    transition: fill 0.3s ease-in;
}

.main-area{
    /*fixing "jumping scrollbar"*/
    margin-left: calc(100vw - 100%);
    margin-right: 0;

    display: flex;
    /*flex-direction: column;*/
    justify-content: center;
    
    padding: 20px;
}

@media only screen and (max-width: 720px){
    h3{
        font-size: 16px;
    }
    h2{ 
        font-size: 18px;
    }
}
@media only screen and (max-width: 625px){
    .main-area{
        padding: 15px 10px 15px 10px;
    }
}

@media only screen and (max-width: 420px){
    .github-link__text{    
        font-size: 16px;
    }
    .header{
        height: 70px;
        padding: 0px 15px;
    }
    .logo > a{
        height: 36.5px;
    }
    .logo svg {
        height: 36.5px;
        width: 138px;
    }
    h3{
        font-size: 14px;
    }
    h2{ 
        font-size: 16px;
    }
}



