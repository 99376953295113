
.train-details{
    height: 270px;
    box-shadow: inset 0px -11px 26px -26px rgba(0,0,0,0.44);
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.train-details__header{
    display: grid;
    grid-template-columns: 4fr 7fr 4fr;
}
.train-details__train-name{
    text-align: center;
}

.train-details__direction1, .train-details__direction2{
    display: flex;
    color: var(--color-grey-light);
    font-size: 14px;
    text-align: left;
}
.train-details__direction1 > div{
    margin-right: 10px;
}
.train-details__direction2{
    flex-direction: row-reverse;
    text-align: right;
}
.train-details__direction2 > div{
    margin-left: 10px;
}

.train-details__cars-seats-data{
    margin-bottom: 20px
}

.train-details__free-seats{
    display: grid;    
    grid-template-rows: auto 40px;    
    row-gap: 10px;    
    width: 94%;
    margin: auto;
    font-weight: 500;
}

.train-details__free-seats-numbers{
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    column-gap: 2px;
}
.train-details__free-seats-numbers > div {
    display: flex;
    justify-content: center;
    align-items: center;
}
.train-details__free-seats-numbers > div > svg{
    margin-right: 5px;
}
.train-details__free-seats:hover{
    cursor: pointer;
}
.train-details__free-seats span {
    margin-left: 3px
}
.train-details__car-images{
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    column-gap: 2px;
}
.train-details__car-images > div {
    border: 2px solid var(--color-grey-dark);
    border-radius: 13px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 18px;
    color: var(--color-grey-lighter);
}
.train-details__car-images > div:first-child{
    border-radius: 65px 25px 25px 65px/40px 25px 25px 40px;
    padding-left: 5px;
}
.train-details__car-images > div:last-child{
    border-radius: 25px 65px 65px 25px/25px 40px 40px 25px;
    padding-right: 5px;
}

/*animations start*/
.train-details__animation-enter {
    max-height: 0;
    opacity: 0.01;
    overflow: hidden;
}  
.train-details__animation-enter.train-details__animation-enter-active {
    transition: 300ms ease-in;
    opacity: 1;
    max-height: 300px;
}  
.train-details__animation-exit {
    max-height: 300px;
    opacity: 1;
}  
.train-details__animation-exit.train-details__animation-exit-active {
    max-height: 0;
    opacity: 0.01;
    transition: 300ms ease-in;
}
  /*animations end*/

.train-details__car-schemes{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.train-details__legend{
    min-width: 50%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
    margin: 1vh auto;
}
.train-details__legend > div{
    display: grid;
    grid-template-columns: min-content auto;
    grid-gap: 5px;
    font-size: 14px;    
}
.train-details__legend > div > div{
    display: flex;
    align-items: center;
}

@media only screen and (max-width: 720px){
    .train-details__direction1, .train-details__direction2{
        font-size: 13px;
    }
    .train-details__free-seats{
        font-size: 14px;
    }
    .train-details__car-images > div {
        font-size: 16px;
    }
}


@media only screen and (max-width: 625px){
    .train-details{
        padding: 8px;
        height: 180px;
    }
    .train-details__direction1, .train-details__direction2{
        font-size: 12px;
        display: block;
    }
    .train-details__free-seats{
        font-size: 13px;
    }
    .train-details__train-name > h3{
        display: none;
    }
    .train-details__direction1 svg, .train-details__direction2 svg{
        width: 30px;
    }
    .train-details__train-name{
        display: none;
    }
    .train-details__header{
        grid-template-columns: 1fr 1fr;
    }

}



@media only screen and (max-width: 440px){
    .train-details{
        height: 180px;
    }
    .train-details__direction1, .train-details__direction2{
        font-size: 12px;
    }
   
    .train-details__legend > div{  
        font-size: 12px;
    }  
    .train-details__free-seats {
        grid-template-rows: auto 30px;
        row-gap: 5px;
    }
    .train-details__free-seats span {
        display: none;
    }
    .train-details__car-images > div {
        font-size: 14px;
    }
   
}

