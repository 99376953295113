@import url(https://fonts.googleapis.com/css?family=Roboto:400,500&display=swap&subset=cyrillic);
em{
    font-style: normal;
    text-decoration: underline;
}


.autocomplete-search {
    margin: auto 10% 8% 10%;
    display:flex;
    align-items:center;
}

.autocomplete-search__input{
    width: 65%;
    height: 45px;
    position: relative;
}
.autocomplete-search__input input{
    background-color: var(--color-text-field-blue);
    border-radius: 5px;
    outline: none;
    border: none;
    width: calc(100% - 20px);
    height: 100%;
    padding: 0px 10px;    

    font-size: 16px;
    color: var(--color-grey-light)
}

.autocomplete-search label{
    margin-right: 8px;
    width: 35%;
}


.autocomplete-search__suggestions{
    position: absolute;    
    width: 100%;
    min-height: 45px;
    max-height: 180px;
    border-radius: 5px;
    padding: 0px;
    margin: 0px;
    color: var(--color-grey-light);
    background-color: white;
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
    
}
.autocomplete-search__suggestions::-webkit-scrollbar {
    width: 5px;
}
.autocomplete-search__suggestions::-webkit-scrollbar-track { 
    background : transparent;
    border-radius: 5px;
}
.autocomplete-search__suggestions::-webkit-scrollbar-thumb { 
    background-color: rgba(137, 139, 139, 0.2);
    border-radius: 5px;    
}
.autocomplete-search__suggestions:hover::-webkit-scrollbar-thumb { 
    background-color: rgba(137, 139, 139, 0.5); 
}

.autocomplete-search__suggestions li{
    list-style-type: none;
    height: 45px;
    display: flex;
    align-items: center;
    padding: 0px 10px;
    
}
.autocomplete-search__suggestions li:hover{
    background-color: var(--color-li-hover);
    cursor: pointer;
}
.autocomplete-search__suggestions li:first-child{
    border-radius: 5px 5px 0px 0px;
}
.autocomplete-search__suggestions li:last-child{
    border-radius: 0px 0px 5px 5px;
}
.autocomplete-search__suggestion_selected{
    background-color: rgb(195, 199, 202)
}

@media only screen and (max-width: 450px){
    .autocomplete-search{
        margin: auto 5% 8% 5%;
    }
    .autocomplete-search__input input{
        font-size: 14px;
    }
}

@media only screen and (max-width: 365px){    
    .autocomplete-search__input input{
        font-size: 13px;
    }
}

.choose-station-window{
    background-color: white;
    border-radius: 8px;
    height: 300px;
    width: 475px;

    margin: 100px auto;
    display: flex;
    flex-direction: column;
}

.choose-station-window__header{
    background-color: var(--color-grey-light);
    border-radius: 8px 8px 0px 0px;
    height: 18%;
    min-height: 35px;
    font-size: 22px;
    color: white;
    padding: 4px;

    display:flex;
    justify-content: center;
    align-items:center;
}

.choose-station-window__button{
    margin: 15px auto 20px auto;
}
.choose-station-window__button > button{
    background-color: var(--color-grey-light);
    color: white;
    border: none;
    outline: none;
    border-radius: 5px;
    width: 130px;
    height: 50px;
    font-size: 16px;
}
.choose-station-window__button > button:hover{
    cursor: pointer;
    background-color: var(--color-button-hover);    
    transition: 0.2s;
}

@media only screen and (max-width: 450px){
    .choose-station-window{
        width: 98%;
        font-size: 14px;
    }
}
@media only screen and (max-width: 365px){
    .choose-station-window{      
        font-size: 13px;
        height: 250px
    }
    .choose-station-window__header{
        font-size: 20px;
    }
    .choose-station-window__button > button{
        font-size: 14px;
    }
}

.gallery{
    width: 100%;
    height: 150px;
    
}
.gallery__grid{
    display: grid;
    grid-template-columns: 1fr 20fr 1fr;
    height: 100%;
}
.gallery__left-arrow, .gallery__right-arrow {
    display: flex;
    align-items: center;
}
.gallery__left-arrow{
    justify-content: flex-end;
}
.gallery__right-arrow{
    justify-content: flex-start;
}
.gallery__left-arrow_active:hover, .gallery__right-arrow_active:hover{
    cursor: pointer;
}
/*slidedr*/

.gallery__frames-cell {
    width: 100%;
    text-align: center;
    overflow: hidden;
    display: flex;
  }
  
  .gallery__frames {
    display: flex;    
    overflow-x: auto;
    -ms-scroll-snap-type: x mandatory;
        scroll-snap-type: x mandatory;    
    /*scroll-behavior: smooth;*/
    -webkit-overflow-scrolling: touch;
    
  }
  .gallery__frames::-webkit-scrollbar {    
    display: none;
  }
 
 
  .gallery__frame_unlabeled {
    scroll-snap-align: start;
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    margin-right: 12%;
    -webkit-transform-origin: center center;
            transform-origin: center center;
    -webkit-transform: scale(1);
            transform: scale(1);
    transition: -webkit-transform 0.5s;
    transition: transform 0.5s;
    transition: transform 0.5s, -webkit-transform 0.5s;
    position: relative;
    
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .gallery__frame_unlabeled > div{
    width: 100%;
    display: flex;
  }
 
  .gallery__frame_labeled {
    scroll-snap-align: start;
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    margin-right: 12%;
    -webkit-transform-origin: center center;
            transform-origin: center center;
    -webkit-transform: scale(1);
            transform: scale(1);
    transition: -webkit-transform 0.5s;
    transition: transform 0.5s;
    transition: transform 0.5s, -webkit-transform 0.5s;
    position: relative;
    
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .gallery__frame_labeled > div{
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-rows: 1fr auto 1fr;
  }
  .gallery__frame-label{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: 500;
  }

@media only screen and (max-width: 625px){
  .gallery__frame-label{
    font-size: 16px;
  }
  .gallery{
    height: 92px;
  }
  .gallery__left-arrow > svg, .gallery__right-arrow > svg{
    width: 80%;
  }
}

@media only screen and (max-width: 440px){

}


.car-scheme{
    display: flex;
}
.car-scheme > svg{
    width: 100%;
    height: 100%;
}

.train-details{
    height: 270px;
    box-shadow: inset 0px -11px 26px -26px rgba(0,0,0,0.44);
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.train-details__header{
    display: grid;
    grid-template-columns: 4fr 7fr 4fr;
}
.train-details__train-name{
    text-align: center;
}

.train-details__direction1, .train-details__direction2{
    display: flex;
    color: var(--color-grey-light);
    font-size: 14px;
    text-align: left;
}
.train-details__direction1 > div{
    margin-right: 10px;
}
.train-details__direction2{
    flex-direction: row-reverse;
    text-align: right;
}
.train-details__direction2 > div{
    margin-left: 10px;
}

.train-details__cars-seats-data{
    margin-bottom: 20px
}

.train-details__free-seats{
    display: grid;    
    grid-template-rows: auto 40px;    
    grid-row-gap: 10px;    
    row-gap: 10px;    
    width: 94%;
    margin: auto;
    font-weight: 500;
}

.train-details__free-seats-numbers{
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-column-gap: 2px;
    -webkit-column-gap: 2px;
            column-gap: 2px;
}
.train-details__free-seats-numbers > div {
    display: flex;
    justify-content: center;
    align-items: center;
}
.train-details__free-seats-numbers > div > svg{
    margin-right: 5px;
}
.train-details__free-seats:hover{
    cursor: pointer;
}
.train-details__free-seats span {
    margin-left: 3px
}
.train-details__car-images{
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-column-gap: 2px;
    -webkit-column-gap: 2px;
            column-gap: 2px;
}
.train-details__car-images > div {
    border: 2px solid var(--color-grey-dark);
    border-radius: 13px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 18px;
    color: var(--color-grey-lighter);
}
.train-details__car-images > div:first-child{
    border-radius: 65px 25px 25px 65px/40px 25px 25px 40px;
    padding-left: 5px;
}
.train-details__car-images > div:last-child{
    border-radius: 25px 65px 65px 25px/25px 40px 40px 25px;
    padding-right: 5px;
}

/*animations start*/
.train-details__animation-enter {
    max-height: 0;
    opacity: 0.01;
    overflow: hidden;
}  
.train-details__animation-enter.train-details__animation-enter-active {
    transition: 300ms ease-in;
    opacity: 1;
    max-height: 300px;
}  
.train-details__animation-exit {
    max-height: 300px;
    opacity: 1;
}  
.train-details__animation-exit.train-details__animation-exit-active {
    max-height: 0;
    opacity: 0.01;
    transition: 300ms ease-in;
}
  /*animations end*/

.train-details__car-schemes{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.train-details__legend{
    min-width: 50%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
    margin: 1vh auto;
}
.train-details__legend > div{
    display: grid;
    grid-template-columns: -webkit-min-content auto;
    grid-template-columns: min-content auto;
    grid-gap: 5px;
    font-size: 14px;    
}
.train-details__legend > div > div{
    display: flex;
    align-items: center;
}

@media only screen and (max-width: 720px){
    .train-details__direction1, .train-details__direction2{
        font-size: 13px;
    }
    .train-details__free-seats{
        font-size: 14px;
    }
    .train-details__car-images > div {
        font-size: 16px;
    }
}


@media only screen and (max-width: 625px){
    .train-details{
        padding: 8px;
        height: 180px;
    }
    .train-details__direction1, .train-details__direction2{
        font-size: 12px;
        display: block;
    }
    .train-details__free-seats{
        font-size: 13px;
    }
    .train-details__train-name > h3{
        display: none;
    }
    .train-details__direction1 svg, .train-details__direction2 svg{
        width: 30px;
    }
    .train-details__train-name{
        display: none;
    }
    .train-details__header{
        grid-template-columns: 1fr 1fr;
    }

}



@media only screen and (max-width: 440px){
    .train-details{
        height: 180px;
    }
    .train-details__direction1, .train-details__direction2{
        font-size: 12px;
    }
   
    .train-details__legend > div{  
        font-size: 12px;
    }  
    .train-details__free-seats {
        grid-template-rows: auto 30px;
        grid-row-gap: 5px;
        row-gap: 5px;
    }
    .train-details__free-seats span {
        display: none;
    }
    .train-details__car-images > div {
        font-size: 14px;
    }
   
}



.timetable-row{
    height: 50px;
    border-bottom: 1px solid #c4c4c4;
    padding: 15px 20px 15px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
/*
.timetable-row:last-child{
    border: none;
}
*/
.timetable-row:hover{
    cursor: pointer;
}

.timetable-row__train-info{
    display: grid;
    grid-template-columns: 62px auto;
    grid-gap: 10px;
}
.timetable-row__train-type{
    display: grid;
    grid-template-rows: 14.4px auto 14.4px;
    
    color: var(--color-red-logo);
    font-size: 12px;
}

.timetable-row__train-type-icon{
    display: flex;
    justify-content: center;
    margin-top: 3px;
}

.timetable-row__train-name{
    font-size: 14px;
    display: flex;
    align-items: center;
    width: 240px;
}

.timetable-row__departure-time{
    font-weight: 500;
}
.timetable-row__stops{
    font-size: 12px;
    color: var(--color-grey-light);
    max-width: 180px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
}
.timetable-row__train-route-info{
    width: 30%;
    margin: auto 8px;
}
.timetable-row__seats{
    font-weight: 500;
    min-width: 100px;
    text-align: right;
    display: flex;
    justify-content: flex-end;
}
.timetable-row__seats > span {
    margin-left: 3px;
}
.timetable-row__seats > svg{
    margin-left: 8px;
}

.timetable-row:hover{
    background-color: #e7e7e7;
}

@media only screen and (max-width: 625px){
    .timetable-row__train-name{
        font-size: 13px;
        width: 145px;
    }
    .timetable-row__seats{
        font-size: 14px;
        min-width: 90px;
    }
    .timetable-row__departure-time{
        font-size: 14px;
    }
    .timetable-row__train-route-info{
        width: 125px;
    }
}
@media only screen and (max-width: 500px){
    .timetable-row__train-route-info{
        width: 100px;
    }
}

@media only screen and (max-width: 440px){
    .timetable-row__train-info{
        grid-template-columns: 28px auto;
    }
    .timetable-row__train-route-info{
        width: 40px;
    }
    .timetable-row__train-type > span{
        display: none;
    }
    
    .timetable-row__train-name{
        font-size: 12px;
        width: 135px;
    }
    .timetable-row__seats{
        min-width: 50px;
    }
    .timetable-row__seats > span {
        display: none;
    }
    .timetable-row__stops{
        font-size: 10px;
    }
    
}



.notifications-block__sticky_container{
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
    z-index: 1;
}
.notifications-block{
    position: absolute;
    padding-left: 20px;
    left: 0px;
    top: 20px;
    width: 208px;
    max-height: 228px;
    overflow-y: auto;

    display: flex;
    flex-direction: column;
}

.notifications-block::-webkit-scrollbar {
    width: 5px;
}
.notifications-block::-webkit-scrollbar-track { 
    background : transparent;
    border-radius: 5px;
}
.notifications-block::-webkit-scrollbar-thumb { 
    background-color: rgba(137, 139, 139, 0.2);
    border-radius: 5px;    
}
.notifications-block:hover::-webkit-scrollbar-thumb { 
    background-color: rgba(137, 139, 139, 0.5); 
}



.notifications-block > div:last-child:hover{
    cursor: pointer;
}

.notification__animation-enter {
    opacity: 0;
    -webkit-transform: translateX(-100px);
            transform: translateX(-100px);
}
.notification__animation-enter-active {    
    -webkit-transform: translateX(0px);    
            transform: translateX(0px);
    opacity: 1;
    transition: 800ms;
}
.notification__animation-exit {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
    opacity: 1;
}
.notification__animation-exit-active {    
    -webkit-transform: translateX(-100px);    
            transform: translateX(-100px);
    opacity: 0;
    transition: 200ms;
}
.notification{
    width: 160px;
    height: 50px;
    background-color: rgb(255, 255, 255, 0.8);
    border-radius: 8px;    
    box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.2);
    padding: 10px 19px;
    font-size: 14px;
    font-weight: 500;

    display: flex;
    align-items: center;
    justify-content: flex-start;

    margin-bottom: 4px;
}

.notification__type{
    margin-right: 15px;
}
.notification__message{
    overflow: hidden;
    text-overflow: ellipsis;
}


.notification_alert{
    border: 1px solid var(--color-grey-light);
}
.notification_error{
    border: 1px solid var(--color-red-logo);
    color: var(--color-red-logo)
}


.station-timetable{
    width: 800px;
    
}
.station-timetable__grid{
    display: grid;
    grid-template-rows: 120px auto;
    grid-gap: 8px;
}

.station-timetable__header{
    padding: 8px;
    background-color: white;
    border-radius: 8px 8px 0px 0px;
    display: flex;
    justify-content: space-between;
}

.station-timetable__go-back{
    font-size: 14px;
    color: var(--color-grey-light);
    width: 184px;
    display: flex;
}
.station-timetable__go-back:hover{
    cursor: pointer;
}
.station-timetable__go-back:before{
    content: url('/images/go-back-arrow.svg');
    padding: 9px 8px 0px 0;
}

.station-timetable__header-info{
    text-align: right;
    margin: auto 30px auto auto;
}
.station-timetable__header-info > hr{
    height: 3px;
    width: 60px;
    background-color: var(--color-red-logo);
    border: none;
    margin: 3px 0px 5px auto;
}
.station-timetable__station-name{
    font-size: 28px;
    font-weight: 500;
}
.station-timetable__current-time{
    color: var(--color-grey-light);
    font-size: 20px;
    font-weight: 500;
}


.station-timetable__body{
    background-color: white;
    border-radius: 0px 0px 8px 8px;
}
.station-timetable__no-results{
    text-align: center;
    font-size: 18px;
    margin: 8% 0px 6% 0px;
    color: var(--color-grey-light)
}
.station-timetable__show-more{
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.station-timetable__show-more:hover{
    cursor: pointer;
}
/* start loading... spinner*/
.loader {
    margin: 20px auto;
    border: 0.25em solid #f3f3f3; 
    border-top: 0.25em solid #546276;
    border-radius: 50%;
    width: 2em;
    height: 2em;
    -webkit-animation: spin 1s linear infinite;
            animation: spin 1s linear infinite;
  }
  
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
  }
  
/*end*/

@media only screen and (max-width: 625px){
    
    .station-timetable__header-info{
        margin: auto 15px auto auto;
    }
    .station-timetable__station-name{
        font-size: 26px;
    }
    .station-timetable__current-time{
        font-size: 18px;
    }
    .station-timetable__go-back{
        display: none;
    }
}

@media only screen and (max-width: 440px){
    .station-timetable__grid{
        grid-template-rows: 100px auto;
    }
    .station-timetable__station-name{
        font-size: 22px;
    }
    .station-timetable__current-time{
        font-size: 16px;
    }
}
:root {
    --color-grey-light: #546276;
    --color-grey-dark: #36495C;
    --color-text-field-blue: #eaf1fa;
    --color-button-hover: rgb(56, 73, 97);
    --color-li-hover: #F2F3F4;
    --color-red-logo: #e41e13;
    --color-grey-lighter: #7F8998;
  }

body {
    background-image: linear-gradient(0deg, #DEE4EC 0%, rgba(203, 211, 211, 0) 100%);
    background-attachment: fixed;
    margin: 0px;
    height: 100%;
    font-family: 'Roboto', sans-serif;
    color: #36495C;
    color: var(--color-grey-dark);
    font-size: 16px;
  }
  
/*
.app, #root, html{
    height: 100%;
}
*/



a{
    text-decoration: none;
    color: #36495C;
    color: var(--color-grey-dark)
}
h1, h2, h3{
    color: #36495C;
    color: var(--color-grey-dark);
    font-weight: 500;  
    margin: 5px;  
}
h1 {
    font-size: 26px;
}
h2 {
    font-size: 20px;
}
h3 {
    font-size: 18px;
}

.header {
    padding: 0px 30px;
    background-color: white;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
    height: 100px;
    display: flex;
    flex-direction: row;   
    justify-content: space-between; 
}

.logo{
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
}
.github-link{
    display: flex;
    margin: auto 3% auto 0;
}
.github-link a{
    display: flex;
    flex-direction: row;
}
.github-link__text{    
    color: #dcdcdc;
    font-size: 18px;
    transition: color 0.3s ease-in;
}
.github-link svg{
    margin-right: 5px;
    width: 18px;
    height: 18px;
    fill: #dcdcdc;    
    transition: fill 0.3s ease-in;
}
.github-link:hover .github-link__text{
    color: #546276;
    color: var(--color-grey-light);
    transition: color 0.3s ease-in;
}
.github-link:hover svg {    
    fill: #546276;    
    fill: var(--color-grey-light);
    transition: fill 0.3s ease-in;
}

.main-area{
    /*fixing "jumping scrollbar"*/
    margin-left: calc(100vw - 100%);
    margin-right: 0;

    display: flex;
    /*flex-direction: column;*/
    justify-content: center;
    
    padding: 20px;
}

@media only screen and (max-width: 720px){
    h3{
        font-size: 16px;
    }
    h2{ 
        font-size: 18px;
    }
}
@media only screen and (max-width: 625px){
    .main-area{
        padding: 15px 10px 15px 10px;
    }
}

@media only screen and (max-width: 420px){
    .github-link__text{    
        font-size: 16px;
    }
    .header{
        height: 70px;
        padding: 0px 15px;
    }
    .logo > a{
        height: 36.5px;
    }
    .logo svg {
        height: 36.5px;
        width: 138px;
    }
    h3{
        font-size: 14px;
    }
    h2{ 
        font-size: 16px;
    }
}




