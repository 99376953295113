em{
    font-style: normal;
    text-decoration: underline;
}


.autocomplete-search {
    margin: auto 10% 8% 10%;
    display:flex;
    align-items:center;
}

.autocomplete-search__input{
    width: 65%;
    height: 45px;
    position: relative;
}
.autocomplete-search__input input{
    background-color: var(--color-text-field-blue);
    border-radius: 5px;
    outline: none;
    border: none;
    width: calc(100% - 20px);
    height: 100%;
    padding: 0px 10px;    

    font-size: 16px;
    color: var(--color-grey-light)
}

.autocomplete-search label{
    margin-right: 8px;
    width: 35%;
}


.autocomplete-search__suggestions{
    position: absolute;    
    width: 100%;
    min-height: 45px;
    max-height: 180px;
    border-radius: 5px;
    padding: 0px;
    margin: 0px;
    color: var(--color-grey-light);
    background-color: white;
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
    
}
.autocomplete-search__suggestions::-webkit-scrollbar {
    width: 5px;
}
.autocomplete-search__suggestions::-webkit-scrollbar-track { 
    background : transparent;
    border-radius: 5px;
}
.autocomplete-search__suggestions::-webkit-scrollbar-thumb { 
    background-color: rgba(137, 139, 139, 0.2);
    border-radius: 5px;    
}
.autocomplete-search__suggestions:hover::-webkit-scrollbar-thumb { 
    background-color: rgba(137, 139, 139, 0.5); 
}

.autocomplete-search__suggestions li{
    list-style-type: none;
    height: 45px;
    display: flex;
    align-items: center;
    padding: 0px 10px;
    
}
.autocomplete-search__suggestions li:hover{
    background-color: var(--color-li-hover);
    cursor: pointer;
}
.autocomplete-search__suggestions li:first-child{
    border-radius: 5px 5px 0px 0px;
}
.autocomplete-search__suggestions li:last-child{
    border-radius: 0px 0px 5px 5px;
}
.autocomplete-search__suggestion_selected{
    background-color: rgb(195, 199, 202)
}

@media only screen and (max-width: 450px){
    .autocomplete-search{
        margin: auto 5% 8% 5%;
    }
    .autocomplete-search__input input{
        font-size: 14px;
    }
}

@media only screen and (max-width: 365px){    
    .autocomplete-search__input input{
        font-size: 13px;
    }
}