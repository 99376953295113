
.gallery{
    width: 100%;
    height: 150px;
    
}
.gallery__grid{
    display: grid;
    grid-template-columns: 1fr 20fr 1fr;
    height: 100%;
}
.gallery__left-arrow, .gallery__right-arrow {
    display: flex;
    align-items: center;
}
.gallery__left-arrow{
    justify-content: flex-end;
}
.gallery__right-arrow{
    justify-content: flex-start;
}
.gallery__left-arrow_active:hover, .gallery__right-arrow_active:hover{
    cursor: pointer;
}
/*slidedr*/

.gallery__frames-cell {
    width: 100%;
    text-align: center;
    overflow: hidden;
    display: flex;
  }
  
  .gallery__frames {
    display: flex;    
    overflow-x: auto;
    scroll-snap-type: x mandatory;    
    /*scroll-behavior: smooth;*/
    -webkit-overflow-scrolling: touch;
    
  }
  .gallery__frames::-webkit-scrollbar {    
    display: none;
  }
 
 
  .gallery__frame_unlabeled {
    scroll-snap-align: start;
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    margin-right: 12%;
    transform-origin: center center;
    transform: scale(1);
    transition: transform 0.5s;
    position: relative;
    
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .gallery__frame_unlabeled > div{
    width: 100%;
    display: flex;
  }
 
  .gallery__frame_labeled {
    scroll-snap-align: start;
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    margin-right: 12%;
    transform-origin: center center;
    transform: scale(1);
    transition: transform 0.5s;
    position: relative;
    
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .gallery__frame_labeled > div{
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-rows: 1fr auto 1fr;
  }
  .gallery__frame-label{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: 500;
  }

@media only screen and (max-width: 625px){
  .gallery__frame-label{
    font-size: 16px;
  }
  .gallery{
    height: 92px;
  }
  .gallery__left-arrow > svg, .gallery__right-arrow > svg{
    width: 80%;
  }
}

@media only screen and (max-width: 440px){

}