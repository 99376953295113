
.notifications-block__sticky_container{
    position: sticky;
    top: 0px;
    z-index: 1;
}
.notifications-block{
    position: absolute;
    padding-left: 20px;
    left: 0px;
    top: 20px;
    width: 208px;
    max-height: 228px;
    overflow-y: auto;

    display: flex;
    flex-direction: column;
}

.notifications-block::-webkit-scrollbar {
    width: 5px;
}
.notifications-block::-webkit-scrollbar-track { 
    background : transparent;
    border-radius: 5px;
}
.notifications-block::-webkit-scrollbar-thumb { 
    background-color: rgba(137, 139, 139, 0.2);
    border-radius: 5px;    
}
.notifications-block:hover::-webkit-scrollbar-thumb { 
    background-color: rgba(137, 139, 139, 0.5); 
}



.notifications-block > div:last-child:hover{
    cursor: pointer;
}

.notification__animation-enter {
    opacity: 0;
    transform: translateX(-100px);
}
.notification__animation-enter-active {    
    transform: translateX(0px);
    opacity: 1;
    transition: 800ms;
}
.notification__animation-exit {
    transform: translateX(0px);
    opacity: 1;
}
.notification__animation-exit-active {    
    transform: translateX(-100px);
    opacity: 0;
    transition: 200ms;
}