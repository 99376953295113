
.timetable-row{
    height: 50px;
    border-bottom: 1px solid #c4c4c4;
    padding: 15px 20px 15px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
/*
.timetable-row:last-child{
    border: none;
}
*/
.timetable-row:hover{
    cursor: pointer;
}

.timetable-row__train-info{
    display: grid;
    grid-template-columns: 62px auto;
    grid-gap: 10px;
}
.timetable-row__train-type{
    display: grid;
    grid-template-rows: 14.4px auto 14.4px;
    
    color: var(--color-red-logo);
    font-size: 12px;
}

.timetable-row__train-type-icon{
    display: flex;
    justify-content: center;
    margin-top: 3px;
}

.timetable-row__train-name{
    font-size: 14px;
    display: flex;
    align-items: center;
    width: 240px;
}

.timetable-row__departure-time{
    font-weight: 500;
}
.timetable-row__stops{
    font-size: 12px;
    color: var(--color-grey-light);
    max-width: 180px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
}
.timetable-row__train-route-info{
    width: 30%;
    margin: auto 8px;
}
.timetable-row__seats{
    font-weight: 500;
    min-width: 100px;
    text-align: right;
    display: flex;
    justify-content: flex-end;
}
.timetable-row__seats > span {
    margin-left: 3px;
}
.timetable-row__seats > svg{
    margin-left: 8px;
}

.timetable-row:hover{
    background-color: #e7e7e7;
}

@media only screen and (max-width: 625px){
    .timetable-row__train-name{
        font-size: 13px;
        width: 145px;
    }
    .timetable-row__seats{
        font-size: 14px;
        min-width: 90px;
    }
    .timetable-row__departure-time{
        font-size: 14px;
    }
    .timetable-row__train-route-info{
        width: 125px;
    }
}
@media only screen and (max-width: 500px){
    .timetable-row__train-route-info{
        width: 100px;
    }
}

@media only screen and (max-width: 440px){
    .timetable-row__train-info{
        grid-template-columns: 28px auto;
    }
    .timetable-row__train-route-info{
        width: 40px;
    }
    .timetable-row__train-type > span{
        display: none;
    }
    
    .timetable-row__train-name{
        font-size: 12px;
        width: 135px;
    }
    .timetable-row__seats{
        min-width: 50px;
    }
    .timetable-row__seats > span {
        display: none;
    }
    .timetable-row__stops{
        font-size: 10px;
    }
    
}

