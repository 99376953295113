.notification{
    width: 160px;
    height: 50px;
    background-color: rgb(255, 255, 255, 0.8);
    border-radius: 8px;    
    box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.2);
    padding: 10px 19px;
    font-size: 14px;
    font-weight: 500;

    display: flex;
    align-items: center;
    justify-content: flex-start;

    margin-bottom: 4px;
}

.notification__type{
    margin-right: 15px;
}
.notification__message{
    overflow: hidden;
    text-overflow: ellipsis;
}


.notification_alert{
    border: 1px solid var(--color-grey-light);
}
.notification_error{
    border: 1px solid var(--color-red-logo);
    color: var(--color-red-logo)
}